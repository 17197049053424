import { MESSAGE_TYPE } from 'shared/constants/messages';
import { IFrameHelper } from 'widget/helpers/utils';

import { showBadgeOnFavicon } from './faviconHelper';

export const initOnEvents = ['click', 'touchstart', 'keypress', 'keydown'];

let audioCtx = null;

export const getAudioContext = () => {
  if (!audioCtx) {
    try {
      audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      console.log('AudioContext created successfully');
    } catch (error) {
      console.error('AudioContext is not available:', error);
    }
  }
  return audioCtx;
};

export const getAlertAudio = async (baseUrl = '', soundFile = '') => {
  const audioCtx = getAudioContext();

  const playsound = audioBuffer => {
    window.playAudioAlert = () => {
      if (audioCtx) {
        const source = audioCtx.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioCtx.destination);
        source.loop = false;
        source.start();
      }
    };
  };

  if (audioCtx) {
    const audioRequest = new Request(soundFile);

    fetch(audioRequest)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.arrayBuffer();
      })
      .then(buffer => {
        audioCtx.decodeAudioData(buffer)
          .then(playsound)
          .catch(error => {
            console.error('Error decoding audio data:', error);
            //alert('Ses dosyası çözümlenemedi: Geçersiz veya bozuk dosya.');
          });
      })
      .catch(error => {
        console.error('Error fetching or processing audio:', error);
        //alert('Ses dosyası alınamadı veya işlenemedi.');
      });
  }
};

// Gelen her mesajda ses çalınsın
export const shouldPlayAudio = (message) => {
  const { message_type: messageType, sender_type: senderType } = message;

  // Eğer message.sender_type varsa konsola yazdır
  if (senderType) {
    console.log(`Sender Type: ${senderType}`);
  }

  // Eğer gönderici User ise ses çalma
  if (senderType === 'User') {
    return false; // User mesajı olduğunda ses çalma
  }

  // Mesaj türü INCOMING ise ses çal
  if (messageType === MESSAGE_TYPE.INCOMING) {
    showBadgeOnFavicon();
    return true; // Her zaman true döndürerek sesin çalmasını sağlıyoruz
  }

  return false;
};

// Mesaj geldiğinde bildirim fonksiyonu
export const newMessageNotification = data => {
  console.log(data);
  const baseUrl = '';  // Eğer kök URL'yi tanımlamak istiyorsanız buraya ekleyin
  const type = 'dashboard';
  const playAudio = shouldPlayAudio(data);
  const customSoundUrl = data.content && data.content.includes('urun-eklendi')
  ? `${baseUrl}/audio/${type}/gift.mp3`  // 'urun-eklendi' içeriyorsa gift.mp3 çal
  : data.content && data.content.includes('customer_close_timeout')
    ? `${baseUrl}/audio/${type}/customer_close_timeout.mp3`  // 'customer_close_timeout' içeriyorsa customer_close_timeout.mp3 çal
    : data.content && data.content.includes('agent_notification_timeout')
      ? `${baseUrl}/audio/${type}/agent_notification_timeout.mp3`  // 'agent_notification_timeout' içeriyorsa agent_notification_timeout.mp3 çal
      : playAudio
        ? `${baseUrl}/audio/${type}/harp.mp3`  // Yeni mesajlar için özel ses dosyası
        : `${baseUrl}/audio/${type}/ding.mp3`; // Diğer durumlar için varsayılan ses dosyası
        
if(data.sender_type === 'User'){

}
else{
  getAlertAudio(baseUrl, customSoundUrl).then(() => {
    if (typeof window.playAudioAlert === 'function') {
      window.playAudioAlert();
    }
  });
}
  showBadgeOnFavicon();
};

// Widget içinde ses çalma işlemi
export const playNewMessageNotificationInWidget = () => {
  IFrameHelper.sendMessage({
    event: 'playAudio',
  });
};

// Sayfa yüklendiğinde ding.mp3 ses dosyasını yükle ve window.playAudioAlert fonksiyonunu hazırla
window.addEventListener('DOMContentLoaded', () => {
  const baseUrl = '';  // Eğer kök URL'yi tanımlamak istiyorsanız buraya ekleyin
  const type = 'dashboard';
  getAlertAudio('', `${baseUrl}/audio/${type}/gift.mp3`);
});
